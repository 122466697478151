import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const MeatSeo = ({ description, title, img, children }) => {
  const router = useRouter();
  const [url, setUrl] = useState();
  useEffect(() => {
    setUrl(window.location.href);
  }, []);
  console.log(router.asPath);

  return (
    <Head>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="author" content="Digital Zeekwet" />
      <meta name="description" content={description} />
      <meta name="date" content="2023-06-08" />
      <meta
        name="facebook-domain-verification"
        content="5h5wf41a33m25s204kkcy6qa7zshrd"
      />
      <meta
        name="keywords"
        content="myeik, digitalzeekwet, digital-marketing, it-service, website, website-service, online-business, digital-solution, events-management, web-design, socialmedia-marketing, digital-in-myeik, dzk-myeik,page-managment,agency,web-development"
      />
      <meta property="fb:pages" content="100765831813842"></meta>
      <meta property="fb:app_id" content="223849627099000"></meta>
      <meta name="robots" content="index,follow" />
      {/* <link
        rel="canonical"
        href={"https://digitalzeekwet.com" + router.asPath}
      ></link> */}
      {children}
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={"https://digitalzeekwet.com" + router.asPath}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={"https://digitalzeekwet.com" + router.asPath}
      />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={img} />
    </Head>
  );
};

export default MeatSeo;
