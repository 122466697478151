import gsap from "gsap";
import { useEffect, useLayoutEffect, useRef } from "react";
import SplitType from "split-type";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const NumberCard = ({ firstText, secondText, number, showPlus }) => {
  const numberCardRef = useRef();
  const numberRef = useRef();
  const textRef = useRef();

  useGSAP(
    () => {
      // const refreshScroll = gsap.delayedCall(1, () => {
      // });
      ScrollTrigger.refresh();
      // refreshScroll;

      const tNumber = gsap.timeline({
        scrollTrigger: {
          trigger: numberCardRef.current,
          // markers: true,
          // toggleActions: "restart pause none reset",
        },
      });

      const splitText = new SplitType(textRef.current, {
        types: ["lines", "chars"],
      });

      tNumber

        .fromTo(
          splitText.chars,
          {
            y: 20,
            yoyoEase: true,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            stagger: 0.01,
            delay: 0.01,
            yoyoEase: true,
            ease: "bounce.out",
          }
        )
        .to(
          numberRef.current.children[0],
          {
            duration: 1,
            textContent: `${number}`,
            roundProps: "textContent",
            ease: "bounce.out",
          },
          "0.3"
        );
    },
    { scope: numberCardRef }
  );

  return (
    <div ref={numberCardRef} className="flex flex-col  md:gap-[30px]">
      <div>
        <p
          ref={numberRef}
          className="Number_gradient-color3 text-[26px]  md:text-[2.8rem] lg:text-[4rem] font-semibold  "
        >
          <span>{0}</span>
          {showPlus && <span>+</span>}
        </p>
      </div>
      <p
        ref={textRef}
        className="text-[#C8DDFB] text-center text-[10px] sm:text-[14px] md:text-xl lg:text-2xl xl:text-[32px] xl:leading-[3rem]"
      >
        {firstText} <br />
        {secondText}
      </p>
    </div>
  );
};

export default NumberCard;
