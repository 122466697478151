import { useRef } from "react";

import Link from "next/link";
import Image from "next/image";

function ProjectCard({
  img,
  clientName,
  serviceName,
  cycleColor,
  slug,
  serviceSlug,
}) {
  const projectImgRef = useRef();
  const cardRef = useRef();

  return (
    <div
      className="flex flex-col gap-[12px] md:flex-basis-1/2 lg:w-[48%] project-card hover:scale-95 transition-transform duration-300 ease-in-out"
      ref={cardRef}
    >
      <div
        className="w-full overflow-hidden cursor-pointer project-img"
        ref={projectImgRef}
      >
        <Link href={`/portfolio/${slug}`}>
          <Image
            src={"https:" + img.url}
            width={img.details.image.width}
            height={img.details.image.height}
            alt={serviceName}
            className="object-cover h-auto w-full"
            layout="responsive"
          />
        </Link>
      </div>
      <div>
        <Link href={`/portfolio/${slug}`}>
          <h3 className="eng-txt text-[#C8DDFB] text-[18px] md:text-[20px] xl:text-[24px] xxl:[28px] project-client">
            {clientName}
          </h3>
        </Link>
        <div className="flex items-center h-auto gap-[12px]">
          <div
            className="w-[8px] md:w-[16px] h-[8px] md:h-[16px] rounded-full"
            style={{ backgroundColor: cycleColor }}
          ></div>
          <Link href={`/services/${serviceSlug}`}>
            <p className="text-[#eceaea] text-[14px] eng-txt">{serviceName}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

// ProjectCard.defaultProps = {
//   cycleColor: "#ffc200",
// };

export default ProjectCard;
