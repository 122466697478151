import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Image from "next/image";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);
const ClientLogoSlide = () => {
  const clients = [
    { id: 1, name: "GLOW Cosmetics Center", img: "glow.png" },
    { id: 10, name: "shwenantheingi", img: "sntg.png" },
    {
      id: 3,
      name: "Southern Treasure Myanmar Construction Co.,Ltd",
      img: "stmc.png",
    },
    {
      id: 4,
      name: "Thu Ka Diamond",
      img: "tkd.png",
    },
    {
      id: 5,
      name: "Easy Rider & Myat ",
      img: "mgdt.png",
    },
    {
      id: 6,
      name: "Car",
      img: "car.png",
    },
    {
      id: 7,
      name: "Fishermen Hotpot and BBQ Restaurant",
      img: "fisher-man.png",
    },
    { id: 8, name: "အားမာန်ဆေးဆိုင် ", img: "arrm-man.png" },
    { id: 9, name: "Hnin Oo Wai ", img: "how.png" },
    { id: 11, name: "Yadanar Linn ", img: "yadanarlinn.png" },
    { id: 12, name: "sannanhtike", img: "sannanhtike.png" },
    { id: 13, name: "level3", img: "level3.png" },
    { id: 14, name: "Meguri Golf Resort", img: "mergui-g.png" },
    { id: 15, name: "Eain Taw Phyu", img: "etp.png" },
    { id: 20, name: "Excellent Treasure Construction", img: "etc.png" },
    { id: 21, name: "Tha Ba Wa", img: "thabawa.png" },
    { id: 22, name: "Bright ERN", img: "be.png" },
  ];
  const container = useRef();
  const refLogos = useRef(null);

  console.log(refLogos.current?.scrollWidth);
  useGSAP(
    () => {
      gsap.set(".client-logo-img ", {
        x: (i) => i * 200,
      });

      gsap.to(".client-logo-img", {
        duration: 60,
        ease: "none",
        x: "+=3350", //move each box 1480px to right
        modifiers: {
          x: gsap.utils.unitize((x) => parseFloat(x) % 3350), //force x value to be between 0 and 1480 using modulus
        },
        repeat: -1,
      });
    },
    { scope: container }
  );

  return (
    <div
      ref={container}
      className="bg-white bg-opacity-20  relative h-[150px]  "
    >
      <div
        ref={refLogos}
        style={{ width: "calc(100% + 200px)" }}
        className=" left-[-200px] h-full overflow-hidden relative  flex flex-nowrap items-center "
      >
        {clients.map((client) => (
          <Image
            key={client.id}
            src={`/assets/image/client-logos/${client.img}`}
            alt={client.name}
            width={200}
            height={100}
            className="client-logo-img w-[200px] absolute "
            title={client.name}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientLogoSlide;
