import Link from "next/link";
import CardItem from "./CardItem";
import Image from "next/image";

const TrendingArticles = ({ articles }) => {
  const { sys, fields } = articles[0];
  const { title, coverPic, shortContent, slug } = fields;
  const { updatedAt } = sys;
  const showArticles = articles.slice(1, 4);

  return (
    <div className="flex flex-col lg:flex-row lg:gap-[64px]]">
      <div className="basis-[50%]">
        <div className="flex flex-col items-start gap-[26px] lg:w-[90%]">
          <Link href={`/blog/` + slug}>
            <Image
              src={"https:" + coverPic.fields.file.url}
              alt="article-cover"
              width={coverPic.fields.file.details.image.width}
              height={coverPic.fields.file.details.image.height}
              sizes="100vw"
              className="w-auto h-[225px] md:w-[100%] lg:w-[513px] md:h-auto"
            />
          </Link>
          <div className="flex flex-col items-start gap-6 mb-[52px]">
            <p className="text-[14px] hidden font-semibold xl:block eng-txt">
              {updatedAt}
            </p>
            <Link href={`/blog/` + slug}>
              <h2 className="mm-txt text-[14px] leading-[24.13px] font-bold md:text-[20px] md:leading-[32.47px]">
                {title}
              </h2>
            </Link>
            <Link href={`/blog/` + slug}>
              <p className="mm-txt text-[12px] leading-[20.68px] font-normal md:text-[16px] md:leading-[27.58px] text-[#76768B]">
                {shortContent}
              </p>
            </Link>
            <Link href={`/blog/` + slug}>
              <button className="eng-txt text-[14px] rounded-[13px] px-[25px] py-[15px] font-bold border-[1.5px] border-[#FAC211]">
                READ MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="basis-[50%]">
        <div className="flex flex-col w-[100%] items-start gap-[21px]">
          {showArticles.map((a) => (
            <CardItem key={a.sys.id} article={a} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingArticles;
