import Image from "next/image";
import Link from "next/link";

function CardItem({ article }) {
  const { sys, fields } = article;
  const { title, coverPic, shortContent, slug } = fields;

  const { updatedAt } = sys;
  return (
    <article className="flex flex-col md:flex md:flex-row ">
      <div className="basis-[30%]">
        <Link href={`/blog/` + slug}>
          <Image
            src={"https:" + coverPic.fields.file.url}
            alt="title"
            width={500}
            height={300}
            // sizes="100px ,(min-width: 768px) 278px, (min-width:992px) 200px"
            className="w-[500px] min-[425px]:w-[140px] md:w-[278px] lg:w-[200px] xl:w-[278px] object-cover"
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Link>
      </div>
      <div className="flex flex-col items-start gap-4 my-[6px] lg:my-0 basis-[70%] md:pl-5">
        <p className="text-[14px] hidden font-semibold xl:block eng-txt">
          {updatedAt}
        </p>
        <Link href={`/blog/` + slug}>
          <h3 className="mm-txt text-[14px] leading-[24.13px] text-[#2985F2] font-bold md:text-[16px] md:leading-[28px] lg:text-[#C5CAD0] line-1">
            {title}
          </h3>
        </Link>
        <Link href={`/blog/` + slug}>
          <p className="mm-txt text-[#76768B] text-[12px] leading-[20.68px] line-2 font-normal">
            {shortContent}
          </p>
        </Link>
        <Link href={`/blog/` + slug}>
          <button className=" eng-txt lg:block text-[12px] border-[1.5px] border-[#FAC211] rounded-[15px] font-bold px-[25px] py-[12px]">
            READ MORE
          </button>
        </Link>
      </div>
    </article>
  );
}

export default CardItem;
