import Image from "next/image";
import eskimi from "@/assets/image/eskimi.png";
import Omini from "@/assets/image/Omini-360.png";
import Dave from "@/assets/image/dave.png";
import Access_Code from '@/assets/image/access_code_team.jpg'

const OurPartnerSection = () => {
  return (
    <section>
      <div className="container mx-auto px-[32px] md:px-[48px] lg:px-[72px]">
        <h1 className="eng-txt text-center text-white text-[28px] font-semibold md:text-[38px] lg:text-[48px]">
          Our <span className="our-partners-text-effect">Partners</span>
        </h1>
        <div className="flex flex-col md:flex-row gap-y-6 md:gap-32 justify-center mt-16 items-center">
          <Image
            src={eskimi}
            alt="eskimi"
            width={140}
            height={52}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <Image
            src={Omini}
            alt="Omini 360 Adversting"
            width={140}
            height={52}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <Image
            src={Dave}
            alt="Dave x Marketing"
            width={140}
            height={52}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <Image
            src={Access_Code}
            alt="Access Code"
            width={140}
            height={52}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default OurPartnerSection;
