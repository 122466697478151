import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import Link from "next/link";
import { useEffect, useLayoutEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

const ServiceCard = ({ service, number, slug }) => {
  const serviceCardRef = useRef();
  const serviceTitleRef = useRef();
  const buttonRef = useRef();
  const serviceNumberRef = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: serviceCardRef.current,
          start: "top bottom",
          end: "bottom +=60%",
          //markers: true,
          id: "service Card",
          scrub: 2,
        },
      });

      tl.fromTo(
        serviceCardRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      )
        .fromTo(
          serviceNumberRef.current,
          {
            opacity: 0,
            y: 20,
          },
          {
            opacity: 1,
            y: 0,
            yoyoEase: true,
            ease: "back(5)",
          },
          "<100%"
        )
        .fromTo(
          serviceTitleRef.current,
          {
            y: 5,
            yoyoEase: true,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            yoyoEase: true,
            ease: "Power3.easeInOut",
          },
          "<50%"
        )
        .fromTo(
          buttonRef.current,
          {
            scale: 1.1,
            opacity: 0,
          },
          {
            opacity: 1,
            scale: 1.1,
          },
          "<50%"
        )
        .fromTo(
          buttonRef.current,
          {
            scale: 1.1,
          },
          {
            scale: 1,
            ease: "back(5)",
            yoyoEase: true,
            yoyo: true,
          },
          "<50%"
        );
    },
    { scope: serviceCardRef }
  );

  return (
    <div
      ref={serviceCardRef}
      className="border-b-2 pb-6 border-[#34383E] w-[100%] flex items-center justify-between lg:justify-between mb-8"
    >
      <div className="flex gap-2 items-center md:gap-6 lg:gap-8">
        <span
          className="eng-txt text-[#8D949E] text-xl font-normal md:text-3xl lg:text-5xl"
          ref={serviceNumberRef}
        >
          {number}
        </span>
        <Link href={`/services/${slug}`}>
          <p
            ref={serviceTitleRef}
            className="eng-txt text-[#C8DDFB] font-medium text-[18px]  leading-[22px]  min-[375px]:text-[16px] md:text-[20px] lg:text-4xl text-start"
          >
            {service}
          </p>
        </Link>
      </div>
      <div>
        <Link href={`/services/${slug}`}>
          <div
            ref={buttonRef}
            style={{ opacity: 0 }}
            className="flex  justify-center items-center rounded-full cursor-pointer bg-[#1C1C1E] w-[42px] h-[42px] md:w-[52px] md:h-[52px] lg:w-[72px] lg:h-[72px] hover:bg-[#FAC221]"
          >
            <FaArrowRight className="text-[#F7FFC9]  md:text-3xl lg:text-4xl hover:text-[#101012]" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
