"use client";
import ServiceCard from "@/components/ServiceCard";
import ProjectCard from "@/components/ProjectCard";
import Image from "next/image";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import SplitType from "split-type";
import NumberCard from "@/components/NumberCard";
import ClientLogoSlide from "@/components/ClientLogoSlide";
import Link from "next/link";
import TrendingArticles from "@/components/TrendingArticles";
import { createClient } from "contentful";
import consultation_img from "@/assets/image/digitalzeekwet_consultation.jpg";
import ebook from "@/assets/image/DZK-ebook.jpg";
import OurPartnerSection from "@/components/OurPartnerSection";
import hero_img from "@/assets/image/digitalzeekwet_team_brain_stroming.jpg";
import home_cover from "@/assets/image/DZK_home_cover.jpg";

import MeatSeo from "@/components/MetaSeo";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

export default function Index({ articles, clientProjects, services }) {
  const [projects, setProjects] = useState();
  const boxRef = useRef();
  const main = useRef();
  const serviceRef = useRef();
  const heroTitleRef = useRef();

  const serviceCardWarpRef = useRef();

  const projectRef = useRef();
  const projectTitleRef = useRef();
  const projectButtonRef = useRef();
  const projectCategoryRef = useRef();
  const projectCardWrap = useRef();

  const freeServiceRef = useRef();
  const freeServiceImgRef = useRef();
  const freeServiceTextRef = useRef();

  const aboutRef = useRef();
  const aboutTextRef = useRef();
  const aboutImgRef = useRef();

  const freeBookRef = useRef();
  const freeBookImgRef = useRef();
  const freeBookTextRef = useRef();

  const countNumber = useRef();
  let h = useRef(null);

  const OnHandleProjectCategory = (service) => {
    const projectsFilter = clientProjects.items.filter((project) => {
      if (project.fields.service.fields.slug == service) {
        return project;
      }
    });
    setProjects(projectsFilter);
  };

  useEffect(() => {
    const projectsSplit = clientProjects.items.slice(0, 6);
    const sort = projectsSplit.sort(
      (a, b) => new Date(b.fields.projectDate) - new Date(a.fields.projectDate)
    );
    setProjects(sort);
  }, [clientProjects]);

  // **Hero Section Animation
  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: main.current,
          start: "-=200 top",
          id: "main",
          // markers: true,
        },
      });

      const mySplitText = new SplitType(heroTitleRef.current, {
        types: "lines",
      });


      // .fromTo(
      //   mySplitText.lines,
      //   { opacity: 0, y: 100, yoyoEase: true },
      //   {
      //     opacity: 1,
      //     y: 0,
      //     stagger: 0.1,
      //     delay: 0.5,
      //     duration: 0.5,
      //     yoyoEase: true,
      //     ease: "bounce.Out",
      //   },
      //   "<50%"
      // )
      
      tl.fromTo(
        boxRef.current,
        {
          scale: 0,
          opacity: 0,
        },
        {
          ease: "ease.out",
          yoyoEase: true,
          scale: 0.8,
          opacity: 0.8,
        },
        "<50%"
      );

      // const sections = document.querySelectorAll("section");
      // console.log(sections);
      // sections.forEach((section) => {
      //   gsap.from(section, {
      //     scrollTrigger: {
      //       trigger: section,
      //       start: "top bottom",
      //       end: "bottom center",
      //       scrub: true, // smooth scrolling effect,
      //      // markers:true
      //     },
      //     opacity: .5,
      //     y: 0,
      //   });
      // });
    },
    { scope: main }
  );

  // useEffect(() => {
  //   (async () => {
  //     const LocomotiveScroll = (await import("locomotive-scroll")).default;

  //     const locomotiveScroll = new LocomotiveScroll();
  //   })();
  // }, []);

  return (
    <>
      <MeatSeo
        title={"Digital Zeekwet – Marketing Communication Firm in Myeik"}
        description={
          "First leading marketing communication firm in Myeik, providing one-stop service for marketing, brand strategy, event, website, appalication and creatives."
        }
        img={"https://digitalzeekwet.com" + home_cover.src}
      />

      <section ref={main} id="home">
        <div>
          <div className="mx-[16.5px] md:mx-[85px] min-[898px]:mx-[155px] min-[1190px]:mx-[155px] min-[1402px]:mx-[182px] min-[1456px]:mx-[250px] mt-[7rem]  md:mt-[1rem]">
            <div
              className="flex justify-center text-center items-center font-semibold my-6 text-[18px] md:mt-32 md:text-[48px] 
         md:leading-[3.5rem]  lg:text-[38px] xl:text-[54px]  xl:leading-[6rem]  xxl:text-[68px] "
            >
              <div
                ref={heroTitleRef}
                className="eng-txt text-[#E6E6E6] text-center"
                style={{
                  opacity: 1,
                }}
              >
                <p>
                  The First Leading &nbsp;
                  <span className="gradient-text hero_gradient_text me-1">
                    Marcom Firm &nbsp;
                  </span>
                </p>
                <p>in Southern Myanmar</p>
              </div>
            </div>
          </div>
          <div className="hero-img">
            <Image
              width={hero_img.width}
              height={hero_img.height}
              src={hero_img}
              alt="Digital Zeekwet Image"
              ref={boxRef}
              className="mx-auto max-md:w-[100%]"
              sizes="100vw"
              style={{
                width: "80%",
                height: "auto",
                opacity: 0,
                scale: 0,
              }}
            />
          </div>
        </div>
      </section>

      <section ref={serviceRef} id="services">
        <div className="container-fluid mx-[28px] sm:mx-[48px] lg:mx-[72px]">
          <div>
            <div className="flex flex-col gap-[20px] lg:flex lg:flex-row mb-10">
              <div className="flex items-center gap-[12px] lg:w-[50%] ">
                <div className="bg-[#F7FFC9]  rounded-full w-[12px] h-[12px] md:w-[14px] md:h-[14px] lg:w-4 lg:h-4 "></div>
                <span className="eng-txt text-[#fff] text-[18px] font-[600]  min-[425px]:text-[32px] ">
                  Services
                </span>
              </div>

              <div className="lg:w-[50%]">
                <p
                  ref={h}
                  className="eng-txt text-[#8D949E] font-medium text-[14px] leading-[19px] min-[425px]:text-[20px] min-[425px]:leading-[32px] md:text-[26px] md:leading-[35px] lg:text-[22px] lg:leading-[33px] "
                >
                  With hundreds of proven records of doubling and even
                  quadrupling our clients{"'"} ROI, we are a team that help you
                  lead your market
                </p>
              </div>
            </div>
            <div>
              {services &&
                services.map((service, index) => (
                  <ServiceCard
                    service={service.fields.displayName}
                    number={`0${index + 1}`}
                    slug={service.fields.slug}
                    key={service.fields.slug}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-[32px] md:px-[72px] mb-6">
          <h2 className="eng-txt text-white text-center text-[32px]  md:text-[38px] lg:text-[48px]">
            Our <span className="our-client-text">Clients</span>
          </h2>
        </div>
        <ClientLogoSlide />
      </section>

      <section ref={projectRef} id="projects">
        <div className="container-fluid mx-[28px] sm:mx-[48px] md:mx-[72px]">
          <div className="">
            <div className="my-7 flex w-100 flex-col-reverse md:flex-row gap-[20px]">
              <div className="md:w-[50%] ">
                <p
                  ref={projectTitleRef}
                  className="text-[#8D9A9E] mb-3 md:mb-6  text-[16px] md:text-[22px] w-100 lg:font-[500] eng-txt"
                >
                  Everything we do begins with business goals and messaging
                  strategies in mind. Let’s see what we can discuss about.
                </p>
                <button
                  ref={projectButtonRef}
                  className=" border-[1.5px] btn-outline-hover z-0 overflow-hidden eng-txt border-[#FAC211] relative border-solid text-[#E6E6E6] text-[16px] p-3 rounded-lg lg:rounded-xl"
                >
                  <span className="absolute w-full h-full  left-0 bg-[#fac211]  z-[-1] overlay"></span>
                  <Link href="/portfolio">
                    <span className="text">SEE PORTFOLIO</span>
                  </Link>
                </button>
              </div>
              <div className="flex gap-[12px] md:w-[50%] md:justify-end items-center section-title">
                <div className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] rounded-full bg-[#381dd4bb]"></div>
                <h2 className="eng-txt text-[#eceaea] text-[25px] md:text-[36px] text-center font-[300] md:font-[400]">
                  Latest Projects
                </h2>
              </div>
            </div>
            <div className="hidden  sticky top-[103px] bg-[#02020b] z-[9]">
              <div className="text-[#8D9A9E] py-5" ref={projectCategoryRef}>
                <ul className="flex  gap-[1rem]  md:text-[10px]  lg:text-[18px] xxl:text-[20px] cursor-pointer eng-txt">
                  <li className="text-[#FAC211]">All</li>
                  {services.map((s_item) => (
                    <li
                      key={s_item.sys.id}
                      onClick={(e) =>
                        OnHandleProjectCategory(s_item.fields.slug)
                      }
                    >
                      {" "}
                      {s_item.fields.serviceName}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-100 bg-[#8D9A9E] h-[1.5px]"></div>
            </div>
            <div
              className="w-100 flex flex-wrap lg:mt-12 gap-8 md:gap-8 justify-center project-card-wrap"
              ref={projectCardWrap}
            >
              {projects &&
                projects.map((project) => (
                  <ProjectCard
                    key={project.sys.id}
                    img={project.fields.heroImage.fields.file}
                    clientName={project.fields.clientName}
                    serviceName={project.fields.service.fields.serviceName}
                    description={project.fields.description}
                    slug={project.fields.slug}
                    cycleColor={project.fields.service.fields.cycleColor}
                    serviceSlug={project.fields.service.fields.slug}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>

      <section id="free-services">
        <div
          ref={freeServiceRef}
          className="container-fluid  overflow-hidden bg-[#102D56] flex flex-col gap-4 lg:gap-10 p-[28px] sm:p-[48px] lg:grid grid-cols-2 place-items-center lg:p-0"
        >
          <div ref={freeServiceImgRef} className="w-full h-full">
            <Image
              src={consultation_img}
              alt="Free Service"
              className=" object-cover lg:h-[100%] lg:object-left-top"
              width={1920}
              height={1393}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          <div
            ref={freeServiceTextRef}
            className="flex flex-col items-start w-[100%] lg:justify-center  lg:my-8"
          >
            <h2 className="md:m-0 text-[18px] md:text-[36px] lg: eng-txt Hero2_gradient_color">
              Expert Marketing Guidance - Free Consultation!
            </h2>
            <div className="text-[#96A4B9] w-full mb-6 flex flex-col md:gap-4 pr-5">
              <p className="text-[12px] eng-txt  leading-[1.3rem] md:leading-8 md:text-[20px]  lg:text-[20px] lg:w-full  mb-3 mt-2">
                Unlock Your Marketing Potential with a Free 1-Hour Consulting
                Service! Get expert guidance for your online presence, social
                media strategy, and overall digital marketing performance.
                Contact us now to schedule your complimentary session!
              </p>
            </div>
            <Link href={"/free-consultation"}>
              <button className="hover:scale-105 duration-500 transition-all ease-in-out eng-txt border-none px-[24px] py-[13px] text-[14px] bg-[#FAC211] text-[#101012] rounded-lg font-bold lg:p-4">
                REGISTER NOW
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section id="about" className="overflow-hidden">
        <div className="container-fluid md:px-[72px]" ref={aboutRef}>
          <div className="w-[100%]">
            <div ref={aboutTextRef}>
              <div className="eng-txt text-[#E6E6E6] text-[18px] mx-[48px] md:mx-20 xl:mx-36   md:text-[50px] lg:text-[58px] md:leading-[87.42px]  text-center font-[600] mb-[24px]">
                <p>
                  <span>About</span>
                  <span className="about_gradient_color ml-1">
                    DIGITAL ZEEKWET
                  </span>
                </p>
                <p className="xl:ml-3">Marcorm Firm</p>
              </div>
              <p className="text-[#8D949E] text-[14px] text-center  md:text-[20px] md:leading-[36px] mx-[28px] sm:mx-[48px] md:mx-12 xl:mx-40  mb-[24px] md:mb-[52px] eng-txt">
                We are an Integrated Performance Marcom Firm that is Bold and
                Caring. Since 2020, we started as a freelancing group, helping
                businesses achieve their marketing goals with our innovative
                Marketing and Strategy. We provide one-stop marketing services
                to generate business revenue in the Digital Era.
              </p>
            </div>
            <div className="w-full" ref={aboutImgRef}>
              <Image
                src="/assets/image/digitalzeekwet_about.jpg"
                alt="Digital Zeekwet About"
                className="w-[55%] mx-auto"
                width={1200}
                height={900}
                sizes="100vw"
              />
            </div>
          </div>
          <div className="mt-[24px] md:mt-[52px] " ref={countNumber}>
            <div className="grid grid-cols-4 items-center text-center mx-[15px] gap-0 min-[330px]:gap-[10px] min-[381px]:gap-[16px] md:gap-[2rem] lg:gap-12 eng-txt">
              <NumberCard
                number={100}
                firstText={"Completed"}
                secondText="Projects"
                showPlus={true}
              />
              <NumberCard
                number={30}
                firstText={"Happy"}
                secondText="Clients"
              />
              <NumberCard
                number={20}
                firstText={"Expert Team"}
                secondText="Members"
              />
              <NumberCard
                number={3}
                firstText={"Years Of"}
                secondText="Establish"
                showPlus={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="free-book">
        <div
          ref={freeBookRef}
          className="container-fluid overflow-hidden bg-[#F3C814] flex flex-col-reverse p-[28px] sm:p-[48px] gap-4 lg:gap-0 lg:grid lg:p-0 lg lg:grid-cols-2 xl:flex-row"
        >
          <div
            ref={freeBookTextRef}
            className="flex flex-col lg:justify-center items-start w-[100%] basis-2/4 gap-[24px] md:gap-[32px] lg:pl-[72px]  lg:pr-6 lg:my-8 xl:pr-10 1xl:pr-40 2xl:pr-56"
          >
            <h2 className="text-[18px] leading-[24.59px] font-medium md:text-[36px] md:leading-[49.18px] md:m-0 gradient-text freebook-gradient eng-txt">
              Consumer decision-making process in digital area
            </h2>
            <p className="text-[12px] leading-[20.68px] md:text-[20px] md:leading-[34.47px] font-bold mm-txt">
              ဒီဂျစ်တယ် မားကတ်တင်းနဲ့မကင်းတဲ့ လုပ်ငန်းရှင် တိုင်းအတွက်
              မဖြစ်မနေသိထားသင့်တဲ့ စားသုံးသူတွေရဲ့ ဆုံးဖြတ်ချက်ချပုံနှင့်
              အဖိုးတန် အချက်အလက်များစွာပါဝင်တဲ့ စာအုပ်ကို အခုပဲ
              ဒေါင်းလုတ်ရယူလိုက်ပါ။
            </p>
            <Link href={"/free-book"}>
              <button className="hover:scale-105 duration-500 transition-all ease-in-out eng-txt border-none py-4 px-4 lg:px-[32px] lg:py-5 text-[14px] bg-[#051731] text-[#FFFFFF] rounded-[15px] font-bold lg:p-4">
                DOWNLOAD BOOK
              </button>
            </Link>
          </div>
          <div className="w-full h-full basis-2/4" ref={freeBookImgRef}>
            <Image
              src={ebook}
              alt="Free Service"
              className="lg:h-[100%] object-cover lg:object-center"
              sizes="100vw"
              height={900}
              width={1200}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </section>
      <OurPartnerSection />

      <section>
        <div className="container-fluid mx-[28px] sm:mx-[48px] lg:mx-[72px] text-[#FFFFFF] flex flex-col gap-5 lg:flex lg:flex-col">
          <div className="flex flex-col lg:flex-row gap-4 md:gap-8 lg:gap-0 lg:mb-10">
            <div className="flex basis-[50%] items-center gap-[12px]">
              <div className="w-[12px] h-[12px] bg-[#F7FFC9] lg:w-[16px] lg:h-[16px] lg:bg-[#AC252D] rounded-lg"></div>
              <h2 className="section-title text-[18px] font-bold leading-[22px] md:text-[36px] eng-txt">
                Trending
                <span className="lg:hidden">Blogs and</span>
                Articles
              </h2>
            </div>
            <div className="basis-[50%]">
              <div className="flex flex-col items-start gap-3 eng-txt">
                <h2 className="text-[#76768B] text-[14px] leading-[19.12px] font-medium md:text-[22px] md:leading-[38.25px]">
                  Discover Our Marketing Journey: A Digital Library of
                  Expertise. Join us on a voyage through our marketing
                  experiences. Press one and embark on a journey through our
                  universe.
                </h2>
                <Link href="/blog">
                  <button className="hidden lg:block text-[14px] rounded-[15px] px-[40px] py-[13px] mt-[1.5rem] font-bold border-[1.5px] border-[#FAC211]">
                    SEE ALL
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <TrendingArticles articles={articles} />
        </div>
      </section>
    </>
  );
}

export async function getStaticProps() {
  const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_KEY,
  });

  const res = await client.getEntries({ content_type: "article" });
  const projects = await client.getEntries({ content_type: "clientProject" });
  const services = await client.getEntries({ content_type: "services" });

  const splitRes = res.items.slice(0, 4);

  return {
    props: {
      articles: splitRes,
      clientProjects: projects,
      services: services.items,
    },
  };
}
